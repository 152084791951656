<template>
  <div
    class="strapi-product-content container md-content"
    :class="contentClass"
    v-html="md.render(component.content)"
  ></div>
</template>

<script lang="ts">
import type { StrapiProductContent } from '~/apollo/types/types';

export default defineComponent({
  name: 'StrapiProductContent',
  props: {
    component: {
      type: Object as PropType<StrapiProductContent>,
      required: true,
    },
    contentClass: {
      type: String,
      default: '',
    },
  },
  setup() {
    const md = useMarkdownIt();

    return {
      md,
    };
  },
});
</script>

<style lang="scss">
@import 'gportal-theme/scss/colors.scss';
@import 'node_modules/bootstrap/scss/mixins/breakpoints';
@import 'node_modules/gportal-theme/scss/breakpoints.scss';

.md-content {
  img {
    max-width: 100%;
  }

  h2 {
    margin: 2rem 0;
  }
  h3 {
    margin: 0.5rem 0;
  }

  ul {
    li {
      list-style-type: none;
      padding-left: 1.5rem;
      margin-bottom: 0.5rem;
      text-indent: -0.75rem;

      @include media-breakpoint-up(md) {
        padding-left: 2rem;
        text-indent: -1rem;
      }

      strong,
      b {
        display: contents;
      }
    }

    li:before {
      // unicode https://www.compart.com/de/unicode/U+2B24
      content: '\2B24';
      font-size: 0.5em;
      display: inline-block;
      color: $gp-gportal-green;
      margin-right: 0.75rem;
      position: relative;
      bottom: 0.2rem;

      @include media-breakpoint-up(md) {
        margin-right: 1rem;
      }
    }
  }

  blockquote {
    border-left: 3px solid $gp-gportal-green;
    padding-left: 1rem;

    p {
      font-style: italic;
      font-size: 1.2rem;
      font-weight: bolder;
    }

    p:before {
      content: '„';
    }
    p:after {
      content: '“';
    }
  }
}
</style>
